import PropTypes from 'prop-types';
import React from 'react';
import { StartGuard } from 'ui/screens';

const StartGuardPage = ({ location }) => {
  return <StartGuard location={location}></StartGuard>;
};

StartGuardPage.propTypes = {
  location: PropTypes.object,
};

StartGuardPage.defaultProps = {};

export default StartGuardPage;
